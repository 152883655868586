import React from 'react';
import { Link } from 'react-router-dom';
import {
  IActiveStage,
  ITableProps,
  IWorkflow,
  ILeadApplication,
  IUser,
} from '@shared-components/models';
import {
  APPLICATION_STATUS,
  COLUMNS,
  LABEL,
  TEXT,
  URL_USER_DETAIL_LINK,
} from '@moxie/constants';
import { ApplicationStatus, Table, UserNameCard } from '@moxie/shared';
import { ApplicationAssignee } from 'libs/shared/src/modules/lead/application/application-assignee';
import { Button } from 'antd';
import { BlockOutlined } from '@ant-design/icons';
import { TableClientApplicationNameCard } from 'libs/shared/src/admin-modules/contact/client-application-name-card';
import { warnNotificationHandler } from '@moxie/shared';

const ApplicationTable = ({
  getData,
  tableName,
  filter,
  tableButtons,
  isTabChanged,
  refreshTable,
  setRefreshTable,
}: ITableProps) => {

  const archivedWarnNotification = () => {
    warnNotificationHandler("Contact is archived.",2)
  }

  const columns = () => {
    return [
      {
        title: TEXT.APPLICATION_ID,
        dataIndex: 'id',
        key: 'id',
        width: 200,
        ellipsis: true,
        render(id: string, data: any) {
          if (data?.client?.archived) {
            return (
              <>
                <Link
                  onClick={archivedWarnNotification}
                  to="#"
                  className="primary-id-color cursor-disabled"
                >
                  <Button
                    type="primary"
                    icon={<BlockOutlined />}
                    className="view_application cursor-disabled"
                  />
                  {data?.company?.company_code + '-' + data?.app_identifier}
                </Link>
              </>
            )
          } else {
            return (
              <Link
                to={`/contact/detail/${data?.client?.id}/applications/${id}`}
                className="primary-id-color"
              >
                <Button
                  type="primary"
                  icon={<BlockOutlined />}
                  className="view_application"
                />
                {data?.company?.company_code + '-' + data?.app_identifier}
              </Link>
            );
          }
        },
      },
      {
        title: COLUMNS.CONTACT_NAME,
        dataIndex: 'client',
        width: 300,
        render(row: any): React.ReactElement {
          return (
            <UserNameCard
              firstName={row?.first_name || ''}
              lastName={row?.last_name || ''}
              email={row?.email || ''}
              link={`/contact/detail/${row?.id}/applications`}
            />
          );
        },
      },
      {
        title: COLUMNS.ADMIN_ASSIGNEE,
        dataIndex: 'agent_clients',
        render(text: any, data: any): React.ReactElement | any {
          return (
            <ApplicationAssignee
              data={data}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              isTabChanged={isTabChanged}
              archivedWarnNotification={archivedWarnNotification}
            />
          );
        },
      },
      {
        title: LABEL.PRODUCT_INSTITUTION_NAME,
        width: 300,
        ellipsis: true,
        sorter: () => 0,
        render(data: ILeadApplication) {
          return <TableClientApplicationNameCard data={data} />;
        },
      },
      {
        title: LABEL.WORKFLOW,
        width: 300,
        ellipsis: true,
        render({ workflow }: { workflow: IWorkflow }) {
          return (
            <>
              <div>{workflow?.workflowType?.name}</div>
              <span className="table-mail-icon text-icon">
                {workflow?.country}
              </span>
            </>
          );
        },
      },
      {
        title: LABEL.CURRENT_STAGE,
        width: 300,
        ellipsis: true,
        render({
          activeStage,
          status,
        }: {
          activeStage: IActiveStage;
          status: string;
        }) {
          return status === APPLICATION_STATUS.COMPLETED
            ? TEXT.COMPLETE
            : activeStage?.stage;
        },
      },
      {
        title: LABEL.STATUS,
        width: 200,
        ellipsis: true,
        render({ status }: { status: string }) {
          return <ApplicationStatus status={status} />;
        },
      },
      {
        title: TEXT.CREATED_BY,
        width: 200,
        render({ created_by }: { created_by: IUser }) {
          return (
            <UserNameCard
              firstName={created_by?.first_name || ''}
              lastName={created_by?.last_name || ''}
              email={created_by?.email || ''}
              link={`${URL_USER_DETAIL_LINK}${created_by?.id}`}
            />
          );
        },
      },
    ];
  };
  return (
    <div className="padding-top-3 bg-white">
      <Table
        filter={filter}
        action={getData}
        tableName={tableName}
        columns={columns()}
        refresh={refreshTable}
        filterOptions={true}
        tableButtons={tableButtons}
      />
    </div>
  );
};

export { ApplicationTable };
